import styles from './Contact.module.scss';
import Contact from '../../views/Contact/Contact';

const ContactSite = () => {
  return (
    <>
      <div className={styles.root}>
        <div className={styles.root_content}> 
          <img src='logo.jpg'></img>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.5290802451514!2d19.545849676954127!3d51.70506229624384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a325559008ee9%3A0x9178763c3063443c!2sCzternastu%20Straconych%2034L%2C%2093-640%20%C5%81%C3%B3d%C5%BA!5e0!3m2!1spl!2spl!4v1681720343835!5m2!1spl!2spl" width="600" height="450" style={{border:0}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <Contact/>
      </div>
    </>
  )
};

export default ContactSite;