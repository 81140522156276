import styles from './LoftFurniture.module.scss';
import { AiOutlineSmallDash } from 'react-icons/ai';
import './LoftFurniture.scss';
import Contact from '../../views/Contact/Contact';
import { Modal, Button } from "react-bootstrap";
import { useState } from 'react';

const images = [
  {
    src: 'uploads/loft/balustrada_0.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_1.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_3.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_4.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_5.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_6.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_7.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/balustrada_8.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/loft1.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/loft2.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/loft3.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
  {
    src: 'uploads/loft/loft4.jpg',
    width: 320,
    height: 250,
    alt: "loft"
  },
];

const LoftFurniture = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [photo, setPhoto] = useState('');

  const imgModal = (e: any) => {
    const imgSrc: String = e.target.src.split('/');
    const imgValue = `${imgSrc[3]}/${imgSrc[4]}/${imgSrc[5]}`;
    setPhoto(imgValue);
    setShow(true);
  }
  return  (
    <>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><img src={photo} style={{width: '100vw', height: '90vh', objectFit: 'contain'}}/></Modal.Body>
      </Modal>
      <div className={styles.root}>
        <div className={styles.root_content}>
          <h2>BALUSTRADY/MEBLE LOFT</h2>
          <h2><AiOutlineSmallDash/></h2>
          <p>&emsp;<span>P</span>rodukujemy najwyższej jakości balustrady wewnętrzne, zewnętrzne oraz meble loft. Każdy z produktów wykonany jest z najwyższą starannością. Konstrukcja wykonana jest ze stali lakierowanej proszkowo na dowolny kolor. Blaty wykonujemy z litego, sezonowanego drewna dębowego lub jesionowego.</p>
        </div>
        <div className={styles.root_gallery}>
          {images.map(image => 
            <div onClick={imgModal} key={image.src}>
              <img src={image.src} style={{width: image.width, height: image.height}} alt={image.alt}></img>
            </div>  
          )}
        </div>
      </div>
      <Contact/>
    </>
  )
};

export default LoftFurniture;