import { AiOutlineSmallDash } from "react-icons/ai";
import styles from  './ShelfStairs.module.scss';
import Contact from "../../views/Contact/Contact";
import { RxDotFilled } from 'react-icons/rx';
import { Modal } from "react-bootstrap";
import { useState }from "react";

const images = [
  {
    src: 'uploads/stairs/stairs1.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs2.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs3.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs4.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs5.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs6.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs7.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs8.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs9.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs10.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs11.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs12.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs13.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs14.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs15.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs16.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
  {
    src: 'uploads/stairs/stairs17.jpg',
    width: 320,
    height: 250,
    alt: "stairs"
  },
];

const ShelfStairs = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [photo, setPhoto] = useState('');

  const imgModal = (e: any) => {
    const imgSrc: String = e.target.src.split('/');
    const imgValue = `${imgSrc[3]}/${imgSrc[4]}/${imgSrc[5]}`;
    setPhoto(imgValue);
    setShow(true);
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><img src={photo} style={{width: '100vw', height: '90vh', objectFit: 'contain'}}/></Modal.Body>
      </Modal>
      <div className={styles.root}>
        <div className={styles.root_content}>
          <h2>SCHODY PÓŁKOWE</h2>
          <h2><AiOutlineSmallDash/></h2>
          <p>&emsp;<span>O</span>ferujemy kompleksowe wykonanie schodów
            półkowych. Nasze wieloletnie doświadczenie
            zaowocowało dopracowaniem technologii
            produkcji oraz montażu schodów.
            <br/>
            Konstrukcje wykonywane przez nas są
            przebadane i bezpieczne.
            <br/>
            <br/>
            Prace zwykle dzieli się na 2 etapy:
            <br/>
            <RxDotFilled/>Konstrukcja stalowa - przed tynkami
            <br/>
            <RxDotFilled/>Montaż obić drewnianych-
            co najmniej 3
            tygodnie po wysuszeniu budynku, najlepiej
            po zakończeniu „cięższych” prac podczas
            których mogłoby dojść do uszkodzenia
            powłoki.
            <br/><br/>
            Do wykończenia stopni stosujemy lite drewno
            jesionowe lub dębowe olejowane w
            nowoczesnej technologii LED UV VESTING.
            <br/>
            Wykonujemy również balustrady stalowe lub
            ze szkła hartowanego.
            <br/>
            Jeżeli przegapiłeś moment montażu
            konstrukcji i jesteś już po tynkach - spokojnie mamy rozwiązania które pozwolą na montaż schodów bez skuwania tynków.
            Działamy na terenie całej Polski.
          </p>
        </div>
        <div className={styles.root_gallery}>
          {images.map(image => 
            <div onClick={imgModal} key={image.src}>
              <img src={image.src} style={{width: image.width, height: image.height}} alt={image.alt}></img>
            </div>  
          )}
        </div>
      </div>
      <Contact/>
    </>
  )
};

export  default  ShelfStairs;